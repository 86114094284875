import React, { useLayoutEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import { useTranslation } from "react-i18next";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { Button, Hidden, useMediaQuery } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Link from "@material-ui/core/Link";
import List from "@material-ui/core/List";
import ImgIcon from "./ImgIcon";
import BotonHeader from "./BotonHeader";
import ButtonLanguage from "./Language";
import Footer from "./MainFooter";
import ScrollToTop from "./ScrollToTop";
import { gsap } from "gsap";

const useStyles = makeStyles((theme) => ({
  barraHeader: {
    width: "100%",
    height: "64px",
    display: "flex",
    justifyContent: "flex-end",
    backgroundColor: "#002E6D",
    [theme.breakpoints.up("md")]: {
      height: "90px",
    },
  },
  toolbar: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    width: "calc(100% -  0px)",
    height: "100%",
    padding: "0px",
    margin: "auto",
    opacity: 1,
  },
  imgFirma: {
    height: "40px",
    marginLeft: "27px",
    [theme.breakpoints.up("md")]: {
      height: "45px",
      marginLeft: "0px",
    },
  },
  textoIdiomas: {
    fontFamily: "Europa-Regular",
    fontSize: "12px",
    lineHeight: "20px",
    color: "#EFEDE9",
    textAlign: "center",
    justifyItems: "center",
    maxWidth: "360px",
  },
  img: {
    width: "100%",
    height: "100%",
  },
  idiomas: {
    display: "flex",
    flexDirection: "row",
    position: "absolute",
    height: "14px",
    top: 10,
    right: 10,
    left: "auto",
  },
  bottonIdioma: {
    padding: "0px 0px !important",
    minWidth: "auto !important",
  },
  botonera: {
    display: "flex",
    flexDirection: "row",
    position: "relative",
    bottom: 0,
    justifyContent: "space-between",
    maxWidth: 1130,
    marginInline: "auto",
    padding: "0px 30px",
    [theme.breakpoints.down("960")]: {
      justifyContent: "space-evenly",
    },
  },
  botones: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    position: "relative",
    alignItems: "center",
    marginTop: "30px",
    "&:focus": {
      outline: "transparent",
    },
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
      marginTop: "0px",
    },
  },
  linea: {
    backgroundSize: "contain",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    width: "5px",
    heigh: "100%",
    padding: "0px 10px",
  },
  botonMenu: {
    position: "absolute",
    top: "45%",
    right: "5%",
    height: "10px",
    width: "15px",
    zIndex: 9999999,
    "&:focus": {
      outline: "transparent",
    },
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  textoBoton: {
    fontFamily: "Optima",
    fontSize: "14px",
    lineHeight: "20px",
    color: "#EFEDE9",
    width: "50%",
    height: "50%",
    textAlign: "center",
    justifyItems: "center",
    textTransform: "none",
  },
  link: {
    textDecoration: "none",
    "&:focus": {
      outline: "transparent",
    },
    "&:hover": {
      textDecoration: "none",
    },
  },
  main: {
    minHeight: "100vh",
    paddingTop: 64,
    [theme.breakpoints.up("md")]: {
      paddingTop: 90,
    },
  },
  list: {
    minHeight: 700,
    width: "100%",
    height: "100%",
    padding: 0,
    backgroundColor: "#f0f0f0",
    [theme.breakpoints.up("xs")]: {
      maxWidth: 500,
    },
  },
  backdrop: {
    backgroundColor: "rgba(0,0,0,0.5)",
    height: "100vh",
    width: "100%",
    position: "fixed",
    zIndex: 20,
    top: 0,
    left: 0,
  },
  backdropWhite: {
    height: "100vh",
    width: "100%",
    position: "fixed",
    zIndex: 999999,
    top: 0,
    left: 0,
  },
}));
const styleDrawer = (theme) => ({
  root: {
    "& div.MuiDrawer-paperAnchorTop": {
      display: "flex",
      width: "100%",
      alignItems: "center",
      justifyContent: "center",
      position: "relative",
    },
    "& .MuiDrawer-paper": {
      top: 64,
      width: "100%",
      backgroundColor: "transparent",
      border: "none",
      height: "cal(100vh - 64px) !important",
      [theme.breakpoints.up("md")]: {
        top: 90,
        height: "cal(100vh - 90px) !important",
      },
    },
    "& div.MuiPaper-root": {
      width: "100%",
      display: "flex",
      alignItems: "flex-end",
      transitionDuration: "1s !important",
      [theme.breakpoints.up("md")]: {
        width: "100%",
        maxWidth: 500,
      },
    },
  },
});

const CustomDrawer = withStyles(styleDrawer)(Drawer);
const MainHeader = (props) => {
  const classes = useStyles();
  const matches = useMediaQuery("(min-width:960px)");
  const [openDrawer, setOpenDrawer] = useState(false);
  const [toolbar, setToolbar] = useState(null);
  useLayoutEffect(() => {
    if (toolbar)
      gsap.fromTo(
        toolbar,
        {
          opacity: 0,
          duration: 1,
        },
        {
          opacity: 1,
          duration: 2,
        }
      );
  }, [toolbar]);

  const toggleDrawer = () => {
    setOpenDrawer(!openDrawer);
  };
  const { t } = useTranslation();
  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };

  const botonesDatos = [
    {
      link: "/",
      text: t("header.title-foundation"),
    },
    {
      link: "/nuestra-historia",
      text: t("header.title-history"),
    },
    {
      link: "/nuestro-trabajo",
      text: t("header.title-work"),
    },
    {
      link: "/comunidad",
      text: t("header.title-comunity"),
    },
  ];

  return (
    <React.Fragment>
      <AppBar position="fixed" className={classes.barraHeader}>
        <Toolbar id="toolbar" ref={setToolbar} className={classes.toolbar}>
          <Hidden smDown>
            <Grid
              container
              alignItems="center"
              justifyContent={matches === true ? "space-between" : "center"}
              className={classes.botonera}
            >
              <Link
                className={classes.link}
                to={{ pathname: "/" }}
                component={RouterLink}
                onClick={handleDrawerClose}
              >
                <img
                  className={classes.imgFirma}
                  src={require("../assets/logo_white.png")}
                  alt={"firma"}
                />
              </Link>

              <nav className={classes.botones}>
                {botonesDatos.map((datos, index) => (
                  <BotonHeader
                    key={index}
                    link={datos.link}
                    textTranslation={datos.text}
                  />
                ))}
                <ButtonLanguage />
              </nav>
            </Grid>
          </Hidden>
          <Hidden mdUp>
            <div style={{ alignItems: "center", justifyItems: "center" }}>
              <Link
                className={classes.link}
                to={{ pathname: "/" }}
                component={RouterLink}
                onClick={handleDrawerClose}
              >
                <img
                  className={classes.imgFirma}
                  src={require("../assets/logo_white.png")}
                  alt={"firma"}
                />
              </Link>
              <Button
                className={classes.botonMenu}
                onClick={() => toggleDrawer(!openDrawer)}
              >
                {openDrawer === false ? (
                  <Typography className={classes.textoBoton}>
                    <ImgIcon
                      className={classes.img}
                      icon={"hamburguesaBlanco"}
                    />
                  </Typography>
                ) : (
                  <Typography className={classes.textoBoton}>
                    <ImgIcon className={classes.img} icon={"xMenu"} />
                  </Typography>
                )}
              </Button>
            </div>
          </Hidden>
        </Toolbar>
      </AppBar>

      {openDrawer && (
        <div className={classes.backdrop} onClick={handleDrawerClose} />
      )}

      <CustomDrawer
        variant="persistent"
        anchor="right"
        open={openDrawer}
        onClick={handleDrawerClose}
      >
        {openDrawer && (
          <List className={classes.list}>
            <nav className={classes.botones}>
              {botonesDatos.map((datos, index) => (
                <BotonHeader
                  key={index}
                  link={datos.link}
                  textTranslation={datos.text}
                />
              ))}
              <ButtonLanguage />
            </nav>
          </List>
        )}
      </CustomDrawer>
      <ScrollToTop>
        <main className={classes.main}>{props.children}</main>
      </ScrollToTop>
      <Footer />
    </React.Fragment>
  );
};

export default MainHeader;
