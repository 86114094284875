import React from "react";

const ImgIcon = ({ icon, className }) => {
  return (
    <img
      className={className}
      src={require(`../assets/icons/${icon}.svg`)}
      alt="icon"
    />
  );
};

export default ImgIcon;
