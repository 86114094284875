import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

const TitleHr = ({ title, align, textDisplay, width, marginTop }) => {
  const useStyles = makeStyles((theme) => ({
    contents: {
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    title: {
      fontSize: "20px",
      fontFamily: "Optima",
      color: "#002E6D",
      [theme.breakpoints.up("md")]: {
        fontSize: "26px",
      },
    },
    line: {
      width: width,
      margin: "auto",
      maxWidth: "140px",
      textAlign: "left",
      border: "none",
      height: 3,
      backgroundColor: "#AA2D29",
      color: "#AA2D29",
      marginTop: marginTop,
      marginBottom: "21px",
    },
  }));
  const classes = useStyles();

  return (
    <div
      className={classes.contents}
      style={{
        display: textDisplay === "none" ? "none" : "inline-block",
        height: "47px",
      }}
    >
      <Typography className={classes.title}>{title}</Typography>
      <hr
        className={classes.line}
        style={{ marginLeft: align === "left" ? 0 : "auto" }}
      />
    </div>
  );
};

export default TitleHr;
