import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import BotonHeader from "./BotonHeader";
import { useTranslation } from "react-i18next";
import { Divider, Grid } from "@material-ui/core";
import TitleHr from "./TitleHr";
import Paragraph from "./Paragraph";
import ImgIcon from "./ImgIcon";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    width: "100%",
    background: "#F0F0F0",
    boxShadow: "none",
  },
  hrBlue: {
    position: "absolute",
    height: 12,
    width: "100%",
    maxWidth: 983,
    margin: "auto",
    left: "50%",
    transform: "translate(-50%,-50%)",
    backgroundColor: "#002E6D",
    top: 0,
    zIndex: 20,
  },
  container: {
    position: "relative",
    zIndex: 10,
    width: "100%",
    maxWidth: 1130,
    margin: "auto",
    padding: "40px 30px 40px",
    flexDirection: "column",

    justifyContent: "center",
    [theme.breakpoints.up("md")]: {
      padding: "10px 30px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "center",
      height: "235px",
    },
  },
  toolbar: {
    display: "none",
    height: "78px",
    margin: "auto",
    justifyContent: "center",
    background: "#002E6D",
    boxShadow: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },

  nav: {
    width: "100%",
    maxWidth: 700,
    margin: "auto",
    display: "flex",
  },
  containerLogo: {
    height: "100%",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    [theme.breakpoints.up("md")]: {
      paddingRight: 100,
    },
  },
  logo: {
    height: "35px",
    [theme.breakpoints.up("md")]: {
      height: "45px",
    },
  },
  containerDivider: {
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "auto",
      height: "100%",
      display: "flex",
      alignItems: "center",
      paddingRight: 33,
    },
  },
  divider: {
    width: "40%",
    margin: "20px auto",
    background: "#002E6D",
    border: "1px solid #002E6D",
    visibility: "hidden",
    [theme.breakpoints.up("sm")]: {
      width: "40%",
    },
    [theme.breakpoints.up("md")]: {
      visibility: "visible",
      width: "20%",
      margin: "auto",
      height: "75%",
    },
  },
  contact: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    marginBottom: 14,
  },
  img: {
    marginRight: 5,
    marginTop: 5,
    height: 15,
  },
}));

const MainFooter = () => {
  const { t } = useTranslation();
  const botonesDatos = [
    {
      link: "/",
      text: t("header.title-foundation"),
    },
    {
      link: "/nuestra-historia",
      text: t("header.title-history"),
    },
    {
      link: "/nuestro-trabajo",
      text: t("header.title-work"),
    },
    {
      link: "/comunidad",
      text: t("header.title-comunity"),
    },
  ];
  const classes = useStyles();
  return (
    <footer className={classes.root}>
      <div className={classes.hrBlue} />
      <Grid container className={classes.container}>
        <Grid item>
          <div className={classes.containerLogo}>
            <img
              height="35px"
              className={classes.logo}
              src={require("../assets/logo_blue.png")}
              alt="firm"
            />
          </div>
        </Grid>
        <Grid item className={classes.containerDivider}>
          <Divider className={classes.divider} />
        </Grid>
        <Grid item>
          <TitleHr width={"90%"} title={t("footer.title")} />
          <div>
            <div className={classes.contact}>
              <ImgIcon className={classes.img} icon={"map"} />{" "}
              <div>
                <Paragraph text={"Arenales 460 (B1638BRD)"} />
                <Paragraph text={"Vicente López, Buenos Aires, Argentina"} />
              </div>
            </div>
          </div>
          <div className={classes.contact}>
            <ImgIcon className={classes.img} icon={"phone"} />
            <a href="tel:+541151988000">
              <Paragraph text={"(54-11) 5198 - 8000"} />
            </a>
          </div>
        </Grid>
      </Grid>
      <Toolbar className={classes.toolbar}>
        <nav className={classes.nav}>
          {botonesDatos.map((datos, index) => (
            <BotonHeader
              key={index}
              link={datos.link}
              textTranslation={datos.text}
              footer={true}
            />
          ))}
        </nav>
      </Toolbar>
    </footer>
  );
};

export default MainFooter;
