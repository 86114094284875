import { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { useLocation } from "react-router";

const ScrollToTop = ({ children, location: { pathname } }) => {
  const location = useLocation();

  useEffect(() => {
    if (location.hash === "#contact"||location.hash === "#newsletter")
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
  }, [pathname,location]);

  return children || null;
};

export default withRouter(ScrollToTop);