import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

const Paragraph = ({ text, padding, textContainerSize, marginBottom }) => {
  const useStyles = makeStyles((theme) => ({
    paragraph: {
      color: "#002E6D",
      fontSize: "14px",
      fontFamily: "Neutra-Book",
      lineHeight: "30px",
      height: "100%",
      paddingTop: padding ? "10px" : "0",
      [theme.breakpoints.up("md")]: {
        fontSize: "16px",
        paddingTop: padding,
        maxWidth: textContainerSize,
        marginBottom: marginBottom,
      },
    },
  }));
  const classes = useStyles();
  return <Typography className={classes.paragraph}>{text}</Typography>;
};

export default Paragraph;
