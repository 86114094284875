import React from "react";
import { Button, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
const useStyles = makeStyles((theme) => ({
  languageButton: {
    color: "#002e6d9e",
    fontFamily: "Optima",
    [theme.breakpoints.up(960)]: {
      right: "-35px",
      color: "#CFD2D3",
      top: "16px",
      position: "absolute",
    },
    [theme.breakpoints.up(1050)]: {
      position: "relative",
      left: "30px",
      right: "0px",
      color: "#CFD2D3",
      top: "0px",
    },
  },
}));

function Languaje() {
  function handleClick(lang) {
    i18next.changeLanguage(lang);
  }
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <div className={classes.language}>
      <Button
        className={classes.languageButton}
        onClick={() => handleClick(t("lang") === "esp" ? "engv1" : "espv1")}
      >
        {t("lang") === "esp" ? "en" : "es"}
      </Button>
    </div>
  );
}

export default Languaje;
