import React from "react";
import { BrowserRouter as Router, Redirect, Switch } from "react-router-dom";
import loadable from "@loadable/component";
import RouteWithHeader from "./components/RouteWithHeader";
import { HelmetProvider } from "react-helmet-async";

const Home = loadable(() => import("./views/Home/MainHome"));
const Historia = loadable(() => import("./views/Historia/MainHistoria"));
const Trabajo = loadable(() => import("./views/Trabajo/MainTrabajo"));
const Comunidad = loadable(() => import("./views/Comunidad/MainComunidad"));

function App() {
  return (
    <HelmetProvider>
      <Router>
        <Switch>
          {/* sections */}
          <RouteWithHeader exact path="/" component={Home} />
          <RouteWithHeader
            exact
            path="/nuestra-historia"
            component={Historia}
          />
          <RouteWithHeader exact path="/nuestro-trabajo" component={Trabajo} />
          <RouteWithHeader exact path="/comunidad" component={Comunidad} />

          {/* redirection */}
          <Redirect to="/" />
          {/* si el path no hace match con alguna de las rutas anteriores lo redirige al home */}
        </Switch>
      </Router>
    </HelmetProvider>
  );
}

export default App;
