import React, { useState, useLayoutEffect } from "react";
import { Button, makeStyles, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import Link from "@material-ui/core/Link";
const BotonHeader = (props) => {
  const { link, textTranslation, footer } = props;
  const [validate, setValidate] = useState(false);
  const [checked, setChecked] = useState(false);
  const { t } = useTranslation();

  useLayoutEffect(() => {
    updateCurrentPath(); // eslint-disable-next-line
  }, [window.location.pathname]);

  const updateCurrentPath = () => {
    if (link === window.location.pathname) {
      setValidate(true);
    } else {
      setValidate(false);
    }
  };
  const useStyles = makeStyles((theme) => ({
    botonHeaderActivado: {
      minWidth: 30,
      position: "relative",
      display: "flex",
      flexDirection: "column",
      borderRadius: "0px",
      margin: "0 30px",
      padding: 0,
      transition: "all 0.7s ease-in-out",
      borderWidth: "0%",
      "& hr": {
        width: "80%",
        opacity: 1,
        transition: "all 0.7s ease-in-out",
        height: "1px",
      },
      "&:focus": {
        outline: "transparent",
      },
      "&:hover": {
        background: "transparent",
      },
      color: "#002E6D",
      [theme.breakpoints.up("md")]: {
        color: "#ffffff",
      },
    },
    botonHeaderDesactivado: {
      minWidth: 30,
      position: "relative",
      display: "flex",
      flexDirection: "column",
      borderRadius: "0px",
      padding: 0,
      margin: "0 30px",
      transition: "all 0.7s ease-in-out",
      borderWidth: "0%",
      "&:focus": {
        outline: "transparent",
      },
      "&:hover": {
        color: "#002E6D",
        transition: "all 0.7s ease-in-out",
        backgroundColor: "transparent !important",
        "& hr": {
          width: "80%",
          opacity: 1,
          transition: "all 0.7s ease-in-out",
          height: "1px",
        },
      },
      color: "#002e6d9e",
      [theme.breakpoints.up("md")]: {
        color: "#CFD2D3",
        "&:hover": {
          color: "#ffffff",
        },
      },
    },
    texto: {
      textTransform: "none",
      fontFamily: "Optima",
      fontSize: footer ? "10px" : "20px",
      lineHeight: "23px",
      letterSpacing: "0px",
      textAlign: "center",
      display: "flex",
      alignItems: "center",
      justifyItems: "center",
      padding: "20px 0px 10px",
      width: "100%",
      [theme.breakpoints.up("md")]: {
        fontSize: footer ? "14px" : "20px",
      },
    },

    subrayado: {
      width: "10%",
      opacity: 0,
      margin: "auto",
      border: "1px solid #AA2D29",
      backgroundColor: "#AA2D29",
      height: "0px",
      color: "#AA2D29",
      position: "absolute",
      bottom: 0,
      transition: "all 0.7s ease-in-out",
    },

    link: {
      height: "64px",
      textDecoration: "none",

      "&:focus": {
        outline: "transparent",
      },
      "&:hover": {
        textDecoration: "none",
      },
    },
  }));
  const classes = useStyles();
  return (
    <React.Fragment>
      <Link className={classes.link} to={link} component={RouterLink}>
        <Button
          disableRipple={true}
          disableFocusRipple
          className={
            validate
              ? classes.botonHeaderActivado
              : classes.botonHeaderDesactivado
          }
          onClick={() => {
            if (link) setChecked(!checked);
          }}
          onMouseEnter={() => setChecked(true)}
          onMouseLeave={() => setChecked(false)}
        >
          <Typography className={classes.texto}>
            {t(textTranslation)}
          </Typography>

          <hr className={classes.subrayado} />
        </Button>
      </Link>
    </React.Fragment>
  );
};

export default BotonHeader;
